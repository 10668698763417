import {FC, useEffect, useRef, useState} from 'react'
import {fabric} from 'fabric'
import {
  printNameCardOrderDownloadReq,
  printNameCardOrderJsonReq,
  printNameCardOrderRemovePDFRef,
} from '../../../../services/Request'
import {
  CDN_URL,
  printNameCardOrderDownload,
  printNameCardOrderJson,
  printNameCardOrderRemovePDF,
} from '../../../../services/main'
import clsx from 'clsx'
import {CanvasDimensions} from '../../../../services/Utils'

type Props = {
  data?: any
}
const exportToBase64 = async (canvas: any) => {
  if (canvas) {
    try {
      const dataURL = canvas.toDataURL({
        format: 'png',
        quality: 1,
        pixelRatio: 3,
        multiplier: 3,
      })
      return dataURL
    } catch (error) {
      console.error('Error exporting to Base64', error)
    }
  }
}
const lockAllObjects = async (canvas: any) => {
  if (!canvas) return

  canvas.getObjects().forEach((obj: any) => {
    if (!obj.userLock) {
      obj.set({
        selectable: false,
        evented: false,
        hasControls: false,
      })
    }
  })

  canvas.renderAll()
}

const unLockAllObjects = async (canvas: any) => {
  if (!canvas) return
  canvas.getObjects().forEach((obj: any) => {
    if (!obj.userLock) {
      obj.set({
        selectable: true,
        evented: true,
        hasControls: true,
      })
    }
  })

  canvas.renderAll()
}

type Font = {
  family: string
  url: string
}

const loadFonts = (fonts: Font[]): Promise<FontFace[]> => {
  return Promise.all(
    fonts.map(({family, url}: Font) =>
      new FontFace(family, `url(${url})`)
        .load()
        .then((loadedFont) => {
          document.fonts.add(loadedFont)
          return loadedFont
        })
        .catch((err) => {
          console.error(`Фонт ачаалж чадсангүй: ${family}`, err)
          return null
        })
    )
  ).then((loadedFonts) => loadedFonts.filter(Boolean) as FontFace[])
}

const homeBackTemplateLoad = async (jsonData: any, canvas: any) => {
  if (canvas) {
    const fontsToLoad = jsonData.objects
    .filter((obj: any) => (obj.type === 'IText' || obj.type === 'Textbox') && obj.fontURL)
    .map((obj: any) => ({
      family: obj.fontFamily,
      url: obj.fontURL,
    }))

  await loadFonts(fontsToLoad)
    try {
      await Promise.all([
        canvas.loadFromJSON(jsonData, () => {
          canvas.requestRenderAll()
        }),
      ])

      canvas.renderAll()
    } catch (error) {
      console.error('Error loading template:', error)
    }
  }
}

const initializeCanvas = async (
  id: any,
  width: any,
  height: any,
  data: any,
  edit: any,
  canvasRef: any
) => {
  const canvas = new fabric.Canvas(id, {
    width: width,
    height: height,
  })

  await homeBackTemplateLoad(data, canvas)
  if (edit === 'lock') {
    lockAllObjects(canvas)
  } else {
    unLockAllObjects(canvas)
  }
  canvasRef.current = canvas
  canvas.renderAll()
  return canvas
}

const Edit: FC<Props> = ({data}) => {
  const [edit, setEdit] = useState<any>('lock')
  const [loading, setLoading] = useState<any>(false)
  const [canvasData, setCanvasData] = useState<any>([])
  const canvasRefBack = useRef<any>(null)
  const canvasRefFront = useRef<any>(null)
  useEffect(() => {
    userOrderJson()
  }, [data])
  const userOrderJson = () => {
    const req = {...printNameCardOrderJsonReq}
    req.file = {
      back: data.back_json,
      front: data.front_json,
    }
    printNameCardOrderJson(req, onGetSuccess, onFailed, 'POST')
  }
  const downloadPdf = (sides: any) => {
    setLoading(true)
    const req = {...printNameCardOrderDownloadReq}
    req.order = {
      sides: sides,
      uid: data.uid,
    }
    printNameCardOrderDownload(req, onDownSuccess, onFailed, 'POST')
  }
  const removePdf = (url: any) => {
    const req = {...printNameCardOrderRemovePDFRef}
    req.order = {
      downloadUrl: url,
    }
    printNameCardOrderRemovePDF(req, onRemoveSuccess, onFailed, 'POST')
  }

  const onGetSuccess = (response: any) => {
    setCanvasData(response)
    const {width, height} = CanvasDimensions(data.size, 'px')
    initializeCanvas('1', width, height, response.front, edit, canvasRefFront)
    initializeCanvas('2', width, height, response.back, edit, canvasRefBack)
  }

  const onRemoveSuccess = (response: any) => {}
  const onDownSuccess = async (response: any) => {
    await DownloadPDF(response.downloadUrl, 'design-image')
  }

  const onFailed = (error: any) => {
    setLoading(false)
  }

  const toggleLock = () => {
    setEdit(() => {
      return edit === 'lock' ? 'normal' : 'lock'
    })
  }
  const [audioPlaying, setAudioPlaying] = useState(false)

  useEffect(() => {
    if (canvasData) {
      if (!audioPlaying) {
        setAudioPlaying(true)
        return
      } else {
        const {width, height} = CanvasDimensions(data.size, 'px')
        initializeCanvas('2', width, height, canvasData.back, edit, canvasRefBack)
        initializeCanvas('1', width, height, canvasData.front, edit, canvasRefFront)
      }
    }
  }, [edit, canvasData])
  const download = async (type: any) => {
    let base64 = ''
    if (type === 'front') {
      base64 = await exportToBase64(canvasRefFront.current)
    } else {
      base64 = await exportToBase64(canvasRefBack.current)
    }

    downloadPdf([base64])
  }
  const DownloadPDF = async (url: any, name: any) => {
    try {
      const response = await fetch(CDN_URL + url)

      if (!response.ok) throw new Error('Network response was not ok')
      const blob = await response.blob()
      const urlObject = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = urlObject
      link.download = name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(urlObject)
    } catch (error) {
      console.error('Image download failed:', error)
    } finally {
      setLoading(false)
      removePdf(url)
    }
  }
  const downloadAll = async () => {
    const frontBase64 = await exportToBase64(canvasRefFront.current)
    const backBase64 = await exportToBase64(canvasRefBack.current)

    downloadPdf([frontBase64, backBase64])
  }
  return (
    <div>
      <div className='card-body py-6'>
        <div className='flex flex-col gap-5'>
          <div className='flex flex-col items-center' style={{width: 'fit-content'}}>
            <div
              className='flex justify-between px-5 items-center bg-secondary'
              style={{width: '100%', height: '50px', borderRadius: '8px 8px 0 0 '}}
            >
              <span style={{textTransform: 'capitalize', fontSize: '16px', fontWeight: '600'}}>
                Урд тал
              </span>
              <div style={{display: 'flex', gap: '10px'}}>
                <button
                  disabled={loading}
                  onClick={() => {
                    downloadAll()
                  }}
                  className='btn btn-primary'
                >
                  {loading ? 'Татаж байна' : '2 талыг татах'}
                </button>
                <button
                  disabled={loading}
                  onClick={() => {
                    download('front')
                  }}
                  className='bg-primary'
                  style={{
                    color: 'white',
                    padding: '6px 16px',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    fontSize: '14px',
                    border: 'none',
                  }}
                >
                  {loading ? 'Татаж байна' : 'Татах'}
                </button>
                <div
                  className='bg-warning flex items-center'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                    padding: '0 16px',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    fontSize: '14px',
                  }}
                  onClick={toggleLock}
                >
                  {edit === 'lock' ? 'Засах' : 'Цуцлах'}
                </div>
              </div>
            </div>

            <div>
              <div
                key={'front' + edit}
                style={{
                  overflow: 'hidden',
                  height: 'auto',
                  transform: `scale(0.7)`,
                  transformOrigin: 'center',
                }}
              >
                {data?.front ? (
                  <canvas
                    ref={canvasRefFront}
                    id='1'
                    style={{
                      zIndex: 999999,
                      boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.9)',
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className='flex flex-col items-center' style={{width: 'fit-content'}}>
            <div
              className='flex justify-between px-5 items-center bg-secondary'
              style={{width: '100%', height: '50px', borderRadius: '8px 8px 0 0 '}}
            >
              <span style={{textTransform: 'capitalize', fontSize: '16px', fontWeight: '600'}}>
                Ар тал
              </span>
              <div style={{display: 'flex', gap: '10px'}}>
                <button
                  disabled={loading}
                  onClick={() => {
                    downloadAll()
                  }}
                  className='btn btn-primary'
                >
                  {loading ? 'Татаж байна' : '2 талыг татах'}
                </button>
                <button
                  disabled={loading}
                  onClick={() => {
                    download('back')
                  }}
                  className='bg-primary'
                  style={{
                    color: 'white',
                    padding: '6px 16px',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    fontSize: '14px',
                    border: 'none',
                  }}
                >
                  {loading ? 'Татаж байна' : 'Татах'}
                </button>
                <div
                  className='bg-warning flex items-center'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                    padding: '0 16px',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    fontSize: '14px',
                  }}
                  onClick={toggleLock}
                >
                  {' '}
                  {edit === 'lock' ? 'Засах' : 'Цуцлах'}
                </div>
              </div>
            </div>

            <div style={{}}>
              <div
                key={'back' + edit}
                style={{
                  overflow: 'hidden',
                  border: '1px solid #eee',
                  height: 'auto',
                  transform: `scale(0.7)`,
                  transformOrigin: 'center',
                }}
              >
                {data?.back ? (
                  <canvas
                    ref={canvasRefBack}
                    id='2'
                    style={{
                      zIndex: 999999,
                      boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.9)',
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Edit}
