import React, {FC, useEffect, useRef, useState} from 'react'
import {
  BaseRequest,
  magicnum,
  PrintboxorderChangeStatusreq,
  PrintboxorderListreq,
  PrintcardorderListreq,
  printTtemplatelistWithStylesReq,
} from '../../../../services/Request'
import {
  CDN_URL,
  PrintboxorderChangeStatus,
  PrintboxorderDelete,
  PrintboxOrderList,
  PrintcardOrderList,
  PrintNamecardOrderChangeStatus,
  PrintNamecardOrderDelete,
  printTtemplatelistWithStyles,
  setPageTitle,
} from '../../../../services/main'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {t} from 'i18next'
import {KTIcon} from '../../../../_metronic/helpers'
import DataTable from '../../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {dateFormat, isNullOrEmpty, moneyFormat} from '../../../../services/Utils'
import {Link} from 'react-router-dom'
import CustomModal from '../../../modules/CustomModal/modal'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import html2canvas from 'html2canvas'
import {Document, Page, pdfjs} from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

type Props = {
  tid?: any
}

const CardOrder: FC<Props> = ({tid}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [item, setItem] = useState<any>([])
  const [statusmodal, setStatusmodal] = useState(false)
  const [nehemjlelmodal, setNehemjlelmodal] = useState(false)
  const [newStatus, setNewStatus] = useState('')
  const nehemjlel = useRef(null)
  const [uid, setUid] = useState('')
  const [showFilter, setShowFilter] = useState(false)
  const [addedDate, setAddedDate] = useState('')
  const [printSides, setprintSides] = useState([])
  const [priceSides, setpriceSides] = useState([])
  const nehemlehDay = 5

  const dashboardBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
  ]
  useEffect(() => {
    // CheckRD()
    setPageTitle('Захиалгийн жагсаалт')
    const result = new Date(item?.created_at)
    const final = new Date(result.setDate(result.getDate() + nehemlehDay))
    setAddedDate(formatDate(final))
  }, [data])

  useEffect(() => {
    orderList()
  }, [PID])

  useEffect(() => {
    var temp = item.sidename
    if (!isNullOrEmpty(temp)) {
      setprintSides(temp.split(','))
    }
    var tempprice = item.sideprice
    if (!isNullOrEmpty(tempprice)) {
      setpriceSides(tempprice.split(','))
    }
  }, [item])

  const orderList = () => {
    setLoading(true)
    var req = PrintcardorderListreq
    req.filter.type = ''
    req.filter.status = ''
    req.filter.uid = ''
    req.filter.payment = ''
    req.filter.year = ''
    req.filter.month = ''
    req.filter.day = ''
    req.filter.tid = tid
    req.pid = PID
    req.pSize = sizePerPage
    PrintcardOrderList(req, onListSuccess, onFailed, 'POST')
  }

  const onListSuccess = (response: any) => {
    setData(response.data)
    setPTotal(response.pagination.ptotal)
    setLoading(false)
  }

  const onFailed = (error: any) => {
    setLoading(false)
    console.log('error:', error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const submit = (row: any) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p> {t('common:confirmDelete')}</p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    PrintNamecardOrderDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const formatDate = (dateString: any) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}.${month}.${day}`
  }
  // const handleDownloadPdf = async (id: string, nehemjlel: React.RefObject<HTMLElement>) => {
  //   try {
  //     const element = nehemjlel.current
  //     if (!element) {
  //       throw new Error('No element found to render PDF.')
  //     }

  //     const images = element.querySelectorAll('img')
  //     const imagePromises = Array.from(images).map((img: HTMLImageElement) => {
  //       return new Promise<void>((resolve, reject) => {
  //         if (img.complete) {
  //           resolve()
  //         } else {
  //           img.onload = () => resolve()
  //           img.onerror = () => reject(new Error(`Failed to load image: ${img.src}`))
  //         }
  //       })
  //     })

  //     await Promise.all(imagePromises)

  //     const canvas = await html2canvas(element, {scale: 2, useCORS: true})
  //     const imgData = canvas.toDataURL('image/png')

  //     const pdf = new jsPDF('p', 'mm', 'a4')
  //     const pdfWidth = pdf.internal.pageSize.getWidth()
  //     const pdfHeight = (canvas.height * pdfWidth) / canvas.width

  //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
  //     pdf.save(`mkl-nehemjeh-${id}.pdf`)
  //   } catch (error) {
  //     console.error('Error creating PDF:', error)
  //   }
  // }
  const onDeleteSuccess = () => {
    orderList()
    return toast.success(t('common:successDelete'))
  }

  const changeStatus = () => {
    var req = PrintboxorderChangeStatusreq
    req.order.id = item?.id
    req.order.status = newStatus
    PrintNamecardOrderChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }

  const onChangeStatusSuccess = (response: any) => {
    setLoading(false)
    setStatusmodal(false)
    orderList()
    return toast.success('Төлөв солигдлоо.')
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <div style={{textAlign: 'center'}}>{cell}</div>
  }

  const paymentRowFormat = (cell: any, row: any) => {
    return cell == 'qpay' ? (
      <div style={{textAlign: 'center', color: 'green', fontWeight: '600'}}>Qpay</div>
    ) : (
      <div
        title='Нэхэмжлэл харах'
        onClick={() => {
          setNehemjlelmodal(true)
          setItem(row)
        }}
        style={{
          cursor: 'pointer',
          padding: '4px 8px',
          width: 'fit-content',
          borderRadius: '6px',
          margin: 'auto',
          backgroundColor: `${
            row.status == 1
              ? '#bdc3c7'
              : row.status == 2
              ? '#f9ca24'
              : row.status == 3
              ? '#4cd137'
              : row.status == 4
              ? '#1e90ff'
              : row.status == 5
              ? '#009432'
              : '#e74c3c'
          }`,
        }}
      >
        Нэхэмжлэл
      </div>
    )
  }
  const templateRowFormat = (cell: any, row: any) => {
    console.log(row)
    return (
      <div className='flex flex-col items-center gap-2'>
        <Link to={`/ordercard/${row.id}`} style={{textAlign: 'center'}}>
          <div className='flex gap-3'>
            <div>
              {row?.front ? (
                <div>
                  <img 
                  style={{ width: '100px', height: 'auto'}} 
                  src={ CDN_URL + row.front} alt={`Front preview of ${row.id}`} />
                </div>
              ) : null}
            </div>
            <div >
              {row?.back ? (
                   <div>
                   <img 
                   style={{ width: '100px', height: 'auto'}}
                   src={ CDN_URL + row.back} alt={`Front preview of ${row.id}`} />
                 </div>
              ) : null}
            </div>
          </div>
        </Link>
      </div>
    )
  }

  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div
        onClick={() => {
          setStatusmodal(true)
          setItem(row)
        }}
        style={{fontSize: '20px', gap: '1px'}}
        className='flex justify-center cursor-pointer'
      >
        <div>
          <div
            style={{
              backgroundColor: `${cell > 1 ? '#1f7f5f' : '#ecf0f1'}`,
              height: '24px',
              width: '24px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <i
              style={{fontSize: '13px', color: cell > 1 ? 'white' : ''}}
              className='fa-solid fa-dollar-sign'
            ></i>
          </div>
        </div>
        <div
          className=''
          style={{
            borderBottom: `1px dashed ${cell > 2 ? '#1f7f5f' : '#a2a5bc'}`,
            width: '20px',
            height: '13px',
          }}
        ></div>
        <div
          style={{
            backgroundColor: `${cell > 2 ? '#1f7f5f' : '#ecf0f1'}`,
            height: '24px',
            width: '24px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <i
            style={{fontSize: '12px', color: cell > 2 ? 'white' : ''}}
            className='fa-solid fa-print'
          ></i>
        </div>
        <div
          className=''
          style={{
            borderBottom: `1px dashed ${cell >= 4 ? '#1f7f5f' : '#a2a5bc'}`,
            width: '20px',
            height: '13px',
          }}
        ></div>
        <div
          style={{
            backgroundColor: `${cell >= 4 ? '#1f7f5f' : '#ecf0f1'}`,
            height: '24px',
            width: '24px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <i
            style={{fontSize: '12px', color: cell >= 4 ? 'white' : ''}}
            className='fa-solid fa-truck-fast'
          ></i>
        </div>
        <div
          className=''
          style={{
            borderBottom: `1px dashed ${cell === 5 ? '#1f7f5f' : '#a2a5bc'}`,
            width: '20px',
            height: '13px',
          }}
        ></div>
        <div
          style={{
            backgroundColor: `${cell === 5 ? '#1f7f5f' : '#ecf0f1'}`,
            height: '24px',
            width: '24px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <i
            style={{fontSize: '13px', color: cell === 5 ? 'white' : ''}}
            className='fa-solid fa-check'
          ></i>
        </div>
      </div>
    )
  }

  const priceRowFormat = (cell: any, row: any) => {
    const totalPrice = row.total
    return (
      <div style={{fontWeight: '600', display: 'flex', flexDirection: 'column'}}>
        <span style={{display: 'flex', alignItems: 'baseline', gap: '4px'}}>
          <span style={{color: 'rgb(59, 130, 246)', fontWeight: '700', fontSize: '13px'}}>
            {moneyFormat(row.count, 'N')}ш
          </span>
        </span>
        <span style={{color: '#3b82f6'}}>{moneyFormat(totalPrice, 'MNT')}</span>
      </div>
    )
  }

  const dateRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col items-center gap-2'>
        <div style={{textAlign: 'center'}}>{dateFormat(cell)}</div>
      </div>
    )
  }

  const sizeRowFormat = (cell: any, row: any) => {
    var tempTsaas = undefined
    if (row.burelt === 1) {
      tempTsaas = magicnum.bureltgu.find((el: any) => el.id === row.tsaas.toString())
    } else if (row.burelt === 2) {
      tempTsaas = magicnum.burelttei.find((el: any) => el.id === row.tsaas.toString())
    }
    return (
      <div style={{display: 'flex', flexDirection: 'column', fontSize: '13px'}}>
        <span>{t(`common:magicnum.nameCardSize.${row.size.split('-')[0]}`)}</span>
        <span>{row.burelt === 1 ? 'Бүрэлтгүй' : 'Бүрэлттэй'}</span>
        <span>{tempTsaas?.name}</span>
        <span>{row.corner === 1 ? 'Дугуй булан' : 'Тэгш булан'}</span>
      </div>
    )
  }

  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-center'>
        <div
          title='Delete'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row)}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  const dimentiontosize = (side: any) => {
    switch (side) {
      case 'front':
        return (
          <div className='flex gap-2 text-sm'>
            <span>Урт: {item?.width}см</span>
            <span>Өргөн :{item?.height}см</span>
          </div>
        )
      case 'right':
        return (
          <div className='flex gap-2 text-sm'>
            <span>Урт: {item?.length}см</span>
            <span>Өргөн :{item?.height}см</span>
          </div>
        )
      case 'back':
        return (
          <div className='flex gap-2 text-sm'>
            <span>Урт: {item?.width}см</span>
            <span>Өргөн :{item?.height}см</span>
          </div>
        )
      case 'left':
        return (
          <div className='flex gap-2 text-sm'>
            <span>Урт: {item?.length}см</span>
            <span>Өргөн :{item?.height}см</span>
          </div>
        )
      case 'top':
        return (
          <div className='flex gap-2 text-sm'>
            <span>Урт: {item?.width}см</span>
            <span>Өргөн :{item?.length}см</span>
          </div>
        )
      case 'bottom':
        return (
          <div className='flex gap-2 text-sm'>
            <span>Урт: {item?.width}см</span>
            <span>Өргөн :{item?.length}см</span>
          </div>
        )
    }
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Загвар',
        dataField: 'name',
        dataSort: false,
        dataFormat: templateRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Мэдээлэл',
        dataField: 'size',
        dataSort: false,
        dataFormat: sizeRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Үнэ, Тоо',
        dataField: 'total',
        dataSort: false,
        dataFormat: priceRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Төлөв',
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Огноо',
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: 'Төлбөр',
        dataField: 'payment',
        dataSort: false,
        dataFormat: paymentRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: '',
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }
  return (
    <div>
      {statusmodal ? (
        <CustomModal
          visible={statusmodal}
          closeModal={() => setStatusmodal(false)}
          className='VideoModal'
        >
          <div style={{padding: '30px', display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <h3>Захиалгийн төлөв солих</h3>
            <select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              className='form-select form-select-sm form-select-solid'
              name=''
              id=''
            >
              {magicnum.PrintStatus.map((el) => {
                return <option value={el}>{t(`common:magicnum.PrintStatus.${el}`)}</option>
              })}
            </select>
            <button onClick={changeStatus} className='btn btn-primary align-self-center'>
              Хадгалах
            </button>
          </div>
        </CustomModal>
      ) : null}
      {nehemjlelmodal ? (
        <CustomModal
          visible={nehemjlelmodal}
          closeModal={() => setNehemjlelmodal(false)}
          className='VideoModal'
        >
          {/* <div style={{padding: '30px 0', width: '960px', maxWidth: '1024px'}}>
            <div style={{position: 'absolute', top: '40px', right: '10px'}}>
              {item?.status == 1 ? (
                <div style={{borderColor: 'grey', color: 'grey'}} className='stamp'>
                  Төлөгдөөгүй
                </div>
              ) : item?.status == 6 ? (
                <div className='stamp'>Цуцлагдсан</div>
              ) : (
                <div style={{border: 'green 4px solid', color: 'green'}} className='stamp'>
                  Төлөгдсөн
                </div>
              )}
            </div>
            <div style={{padding: '48px 24px', position: 'relative'}} ref={nehemjlel}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                }}
              >
                <h2
                  style={{
                    padding: '8px',
                    width: '350px',
                    backgroundColor: '#0f243e',
                    color: 'white',
                    fontSize: '24px',
                  }}
                >
                  Grado
                </h2>
                <span style={{fontSize: '24px', fontWeight: '600'}}>
                  Нэхэмжлэх №: W-{String(item?.id).padStart(3, '0')}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '36px',
                }}
              >
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <span>Утас: 77779969, И-мэйл: grado.mn</span>
                  <span>Регистрийн дугаар: 6942326</span>
                  <span>Банкны нэр: МБанк</span>
                  <span>Дансны дугаар: 9006942326 /төгрөгийн/</span>
                  <span>Захиалгын дугаар: W-{String(item?.id).padStart(3, '0')}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <span style={{maxWidth: '300px'}}>Худалдан авагчийн нэр: {item.uname}</span>
                  <span>Нэхэмжилсэн огноо: {dateFormat(item.created_at)}</span>
                  <span>Төлбөр хийх хугацаа: {addedDate}</span>
                </div>
              </div>
              <div>
                <table className='table cart-table table-responsive-xs'>
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>Төрөл</th>
                      <th>Хэмжээ</th>
                      <th>Захиалсан тоо</th>
                      <th>Нийт үнэ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>{item?.type}</td>
                      <td>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                          <span>Урт: {item?.width}</span>
                          <span>Өргөн: {item?.height}</span>
                          <span>Өндөр: {item?.length}</span>
                        </div>
                      </td>
                      <td>{item.too}</td>
                      <td>{moneyFormat(item?.price, 'MNT')}</td>
                    </tr>
                  </tbody>
                </table>
                <table className='table cart-table table-responsive-md mt-5'>
                  <tfoot>
                    <tr style={{borderTop: '1px solid black'}}>
                      <td style={{fontWeight: 'normal'}}>НИЙТ /НӨАТ-тай/</td>
                      <td>
                        <h2 style={{fontSize: 16, textAlign: 'end'}}>
                          {moneyFormat(item.price, 'MNT')}{' '}
                        </h2>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: '80px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '12px',
                    }}
                  >
                    <span>/Тамга/</span>
                    <img src='/img/sanhuu-tamga.png' alt='' />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      fontSize: '14px',
                    }}
                  >
                    <span>Дарга: ..............................................</span>
                    <span>Нягтлан бодогч: .................................</span>
                  </div>
                </div>
              </div>
            </div>

            <div style={{position: 'absolute', top: '20px', left: '20px'}}>
              <button
                style={{
                  padding: '10px 20px',
                  borderRadius: '8px',
                  backgroundColor: '#3498db',
                  border: 'none',
                  color: 'white',
                  fontSize: '15px',
                }}
                onClick={() => handleDownloadPdf(item.id as string, nehemjlel)}
              >
                Download as PDF
              </button>
            </div>
          </div> */}
          <div style={{padding: '30px 0', width: '960px', maxWidth: '1024px'}}>
            <div style={{position: 'absolute', top: '40px', right: '10px'}}>
              {item?.status == 1 ? (
                <div style={{borderColor: 'grey', color: 'grey'}} className='stamp'>
                  Төлөгдөөгүй
                </div>
              ) : item?.status == 6 ? (
                <div className='stamp'>Цуцлагдсан</div>
              ) : (
                <div style={{border: 'green 4px solid', color: 'green'}} className='stamp'>
                  Төлөгдсөн
                </div>
              )}
            </div>
            <div style={{padding: '48px 24px', position: 'relative'}} ref={nehemjlel}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                }}
              >
                <h2
                  style={{
                    padding: '8px',
                    width: '350px',
                    backgroundColor: '#0f243e',
                    color: 'white',
                    fontSize: '24px',
                  }}
                >
                  Grado
                </h2>
                <span style={{fontSize: '24px', fontWeight: '600'}}>Нэхэмжлэх №: W-{item?.id}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '36px',
                  fontSize: '14px',
                }}
              >
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <span>Утас: 77779969, И-мэйл: grado.mn</span>
                  <span>Регистрийн дугаар: 6942326</span>
                  <span>Банкны нэр: МБанк</span>
                  <span>Дансны дугаар: 9006942326 /төгрөгийн/</span>
                  <span>Захиалгын дугаар: W-{item?.id}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <span style={{maxWidth: '300px'}}>
                    Худалдан авагчийн нэр:{' '}
                    {!isNullOrEmpty(item?.org_rd) ? item.org_name : item.uname}
                  </span>
                  {!isNullOrEmpty(item?.org_rd) ? (
                    <span>Регистрийн дугаар: {item?.org_rd}</span>
                  ) : (
                    <span>Email хаяг: {item?.email}</span>
                  )}

                  <span>Нэхэмжилсэн огноо: {dateFormat(item.created_at)}</span>
                  <span>Төлбөр хийх хугацаа: {addedDate}</span>
                </div>
              </div>
              <div>
                <table className='w-full mt-3'>
                  <thead>
                    <tr
                      style={{
                        borderBottom: '1px solid rgba(0,0,0,0.5)',
                      }}
                    >
                      <th>№</th>
                      <th>Төрөл</th>
                      <th>
                        <h2 className=''>Хэмжээ</h2>
                      </th>
                      <th>
                        <h2 className='w-full text-center'>Захиалсан тоо</h2>
                      </th>
                      <th>
                        <h2 className='w-full text-right'>Ширхэгийн үнэ</h2>
                      </th>
                      <th>
                        <h2 className='w-full text-right'>Нийт үнэ</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <h2 className='font-bold text-sm'>Хайрцаг</h2>
                    </tr>
                    <tr
                      style={{
                        borderBottom: '1px solid rgba(0,0,0,0.5)',
                      }}
                    >
                      <td className='font-semibold'>1</td>
                      <td>
                        <span className='font-semibold'>{item?.type}</span>
                      </td>
                      <td>
                        <div className='text-sm flex gap-2'>
                          <span>Урт: {item?.width}см</span>
                          <span>Өргөн: {item?.height}см</span>
                          <span>Өндөр: {item?.length}см</span>
                        </div>
                      </td>
                      <td>
                        <h2 className='w-full text-center'>{item.count}</h2>
                      </td>
                      <td>
                        <h2 className='font-semibold w-full text-right'>
                          {moneyFormat(item?.price, 'MNT')}
                        </h2>
                      </td>
                      <td>
                        <h2 className='font-semibold w-full text-right'>
                          {moneyFormat(item?.price * item?.count, 'MNT')}
                        </h2>
                      </td>
                    </tr>

                    <tr>
                      <h2 className='font-bold text-sm'>Хэвлэл</h2>
                    </tr>
                    {printSides.map((el, i) => {
                      return (
                        <tr
                        // style={{
                        //   borderBottom: "1px solid rgba(0,0,0,0.5)",
                        // }}
                        >
                          <td className='font-semibold'>{i + 1}</td>
                          <td>
                            <span className='font-semibold text-sm'>
                              {el === 'front'
                                ? 'Урд'
                                : el === 'back'
                                ? 'Ар'
                                : el === 'right'
                                ? 'Баруун'
                                : el === 'left'
                                ? 'Зүүн'
                                : el === 'top'
                                ? 'Дээд'
                                : 'Доод'}
                            </span>
                          </td>
                          <td>{dimentiontosize(el)}</td>
                          <td>
                            <h2 className='w-full text-center'>{item.count}</h2>
                          </td>
                          <td>
                            <h2 className='font-semibold w-full text-right'>
                              {moneyFormat(priceSides?.[i], 'MNT')}
                            </h2>
                          </td>
                          <td>
                            <h2 className='font-semibold w-full text-right'>
                              {moneyFormat(priceSides?.[i] * item?.count, 'MNT')}
                            </h2>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <table className='w-full mt-8'>
                  <tfoot>
                    <tr style={{borderTop: '1px solid black'}}>
                      <td style={{fontWeight: 'normal'}}>НИЙТ /НӨАТ-тай/</td>
                      <td>
                        <h2
                          style={{
                            fontSize: 16,
                            fontWeight: '700',
                            textAlign: 'end',
                          }}
                        >
                          {moneyFormat(
                            (parseInt(item.price) + parseInt(item.printprice)) * item.count,
                            'MNT'
                          )}{' '}
                        </h2>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: '80px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '12px',
                    }}
                  >
                    <span>/Тамга/</span>
                    <img src='/img/sanhuu-tamga.png' alt='' />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      fontSize: '14px',
                    }}
                  >
                    <span>Дарга: ..............................................</span>
                    <span>Нягтлан бодогч: .................................</span>
                  </div>
                </div>
              </div>
            </div>

            <div style={{position: 'absolute', top: '20px', left: '20px'}}>
              <button
                style={{
                  padding: '10px 20px',
                  borderRadius: '8px',
                  backgroundColor: '#3498db',
                  border: 'none',
                  color: 'white',
                  fontSize: '15px',
                }}
                // onClick={() => handleDownloadPdf(item.id, nehemjlel)}
              >
                Download as PDF
              </button>
            </div>
          </div>
        </CustomModal>
      ) : null}
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Orders</PageTitle>
      <div className={`card mb-5`}>
        <div className='card-header border-1' style={{height: '60px'}}>
          <div
            onClick={() => setShowFilter(!showFilter)}
            className='filter flex items-center justify-center gap-0 cursor-pointer'
          >
            <KTIcon iconName='filter' className='fs-3' />
            <span className='text'>{t('common:filter')}</span>
          </div>
          <div className='card-toolbar'>
            <div onClick={() => orderList()} className='btn btn-sm btn-light-primary'>
              {t('common:search')}
            </div>
          </div>
        </div>
        {showFilter ? (
          <div className='card-body py-3'>
            <div className='row flex items-center filter-wrap'>
              <div className='col'>
                <input
                  className='form-control form-control-sm form-control-solid'
                  type='number'
                  onChange={(e) => setUid(e.target.value)}
                  placeholder='UID'
                  value={uid}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Захиалгийн жагсаалт</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {'Нийт ' + PTotal + ' захиалга'}
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          ></div>
        </div>

        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
}

export default CardOrder
