import {IsPrivilege, getRealData, isNullOrEmpty} from './Utils'
import config from './config.json'
import CryptoJS from 'crypto-js'
var Sale = config.Sale
var Sale2 = config.Sale2
var GRADOEDU = config.GRADOEDU
var GRADOORG = config.GRADOORG
var GRADOSTA = config.GRADOSTA
var varBASE_URL = ''
var varCDN_URL = ''
var debugMode = config.debug
var cookieMode = config.cookie
if (debugMode === 'local') {
  varCDN_URL = config.CDN_URL
  varCDN_URL = config.RealCDN_URL
  varBASE_URL = config.CDN_URL + '/'
} else if (debugMode === 'real') {
  varCDN_URL = config.RealCDN_URL
  varBASE_URL = config.RealCDN_URL + '/'
} else {
  varCDN_URL = config.TestCDN_URL
  varBASE_URL = config.TestCDN_URL + '/'
}

export const BASE_URL = varBASE_URL
export const CDN_URL = varCDN_URL
export const SALE = Sale
export const SALE2 = Sale2
export const PRICE_EDU = GRADOEDU
export const PRICE_ORG = GRADOORG
export const PRICE_STA = GRADOSTA
export function setPageTitle(text) {
  document.title = text + ' | ' + ' grado.mn'
}

function getCookie(name) {
  let cookie = {}
  document.cookie.split(';').forEach(function (el) {
    let [k, v] = el.split('=')
    cookie[k.trim()] = v
  })
  return cookie[name]
}

function isPriv(url, method, failCallback) {
  var isprivNo = false //false bn shuu
  var realprivNoArray = url.split('/') //api/Library/LibraryList
  var realprivNo = '' //Employee-Post

  if (realprivNoArray[0] === 'api') {
    for (var i = 0; i <= realprivNoArray.length - 1; i++) {
      if (realprivNoArray[i] !== 'api' && realprivNoArray[i] !== '' && i <= 2) {
        realprivNo += realprivNoArray[i]
        realprivNo += '-'
      }
    }
    realprivNo = realprivNo + method
    if (IsPrivilege(realprivNo)) {
      isprivNo = true
    }
  } else if (realprivNoArray[0] === 'publicapi') {
    isprivNo = true
  }

  return isprivNo
} //ene code client der erh shalgaj baina ta harj bnu?ok

function deleteAllCookies() {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    if (cookieMode === 'test') {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=localhost; path=/`
    } else {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.grado.mn; path=/`
    }
    localStorage.clear()
    // document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

function baseFetch(url, request, successCallback, failCallback, rMethod, rHeader) {
  const EmployeeID = getCookie('GAEmployeeID')
  const SystemToken = getCookie('GASystemToken')
  if (isPriv(url, rMethod, failCallback)) {
    var statusCode = ''
    var baseHeader = {
      'x-auth-id': EmployeeID,
      'x-auth-token': SystemToken,
      'x-api-key': CryptoJS.AES.encrypt(config.keyAPI, config.engryptPass).toString(),
      'Content-Type': 'application/json',
      lang: 'mn',
    }
    var baseHeaderFileUpload = {
      'x-auth-id': EmployeeID,
      'x-auth-token': SystemToken,
      'x-api-key': CryptoJS.AES.encrypt(config.keyAPI, config.engryptPass).toString(),
      lang: 'mn',
    }
    fetch(BASE_URL + url, {
      crossDomain: false,
      // mode: "cors",
      method: rMethod,
      headers: rHeader == 'file' ? baseHeaderFileUpload : baseHeader,
      body: rHeader == 'file' ? request : rMethod !== 'GET' ? JSON.stringify(request) : null,
    })
      .then((res) => {
        statusCode = res.status
        return res.json()
      })
      .then(function (data) {
        if (statusCode === 401) {
          deleteAllCookies()
          window.location.replace('/auth')
        } else if (statusCode === 200) {
          TokenHandler(url, data, successCallback)
        } else {
          errorJob(url, data.statusText, failCallback)
        }
      })
      .catch(function (error) {
        console.log('basefetch error:', error)
        errorJob(url, 'Алдаа гарсан тул, шинээр нэвтэрнэ үү', failCallback)
        window.location.replace('#/err')
      })
  } else {
    errorJob(url, 'Хандах эрх байхгүй байна ...', failCallback)
  }
} //baseFetch gedeg api duuddag yum bgaa js ter ni neg api duudhad iim coed bichigddeg teriig ni bagasgatsan bgaa  TypeZurchilList(req, this.onSuccess, this.onFailed, "POST");

function TokenHandler(url, response, successCallback) {
  var bytes = CryptoJS.AES.decrypt(response, config.engryptPass)
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  successCallback(decryptedData)
}

function errorJob(url, error, failCallback) {
  failCallback(error)
  //return toast.error(error);
}

function errorFetch(url, request) {
  var baseHeader = {
    Authorization: 'Bearer ' + getRealData('GASystemToken'),
    'x-api-key': config.keyAPI,
    'Content-Type': 'application/json',
    Language: localStorage.getItem('Lang'),
  }
  fetch(BASE_URL + url, {
    method: 'POST',
    headers: baseHeader,
    body: JSON.stringify(request),
  }).catch((error) => console.error('Error:', error))
}

//#region [ AuthCreate & Verify ]

// Нэвтрэх үед token авах
export function loginGetToken(request, successCallback, failCallback, method) {
  baseFetch('publicapi/auth/signin', request, successCallback, failCallback, method)
  return ''
}

export function signOut(request, successCallback, failCallback, method) {
  baseFetch('publicapi/auth/signout', request, successCallback, failCallback, method)
  return ''
}
//#endregion

//#region [ Ажилчдын жагсаалт ]
export function CheckRdtoName(
  id,
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/designer/checkRDtoName/" + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}

export function listEmployee(request, successCallback, failCallback, method) {
  baseFetch('api/employee/getallemployee', request, successCallback, failCallback, method)
  return ''
}

export function EmployeeChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/employee/changeStatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function EmployeeChangeInfo(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/employee/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function EmployeeChangePhoto(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/employee/changephoto', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function EmployeeAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/employee/addemployee', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function ChangePass(request, successCallback, failCallback, method) {
  baseFetch('api/employee/changepassword', request, successCallback, failCallback, method)
  return ''
}

export function GetEmployee(id, request, successCallback, failCallback, method) {
  baseFetch('api/employee/getemployee/' + id, request, successCallback, failCallback, method)
  return ''
}

export function EmployeeDelete(employeeID, request, successCallback, failCallback, method) {
  baseFetch(
    'api/employee/deleteemployee/' + employeeID,
    request,
    successCallback,
    failCallback,
    method
  )
  return ''
}

//#endregion

//#region [PrintBox]

export function PrintboxOrderGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/print_boxorder/get/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PrintboxOrderList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/print_boxorder/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PrintNamecardOrderGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_namecard_order/get/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function PrintcardOrderList(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_namecard_order/list',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function PrintNamecardOrderChangeStatus(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    'api/print_namecard_order/changestatus',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function printTtemplateListBack(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/print_template/backlist",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
export function printTtemplateBackChangeStatus(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    "api/print_template/backchangestatus",
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  );
  return "";
}
export function printTtemplateBackDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_template/backdelete/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
export function printTemplateBackUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/print_template/backupdate', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PrintboxorderChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_boxorder/changestatus',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function PrintboxorderDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_boxorder/delete/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function PrintNamecardOrderDelete(
  id,
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    'api/print_namecard_order/delete/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function PrintboxOrderDesignList(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_boxorder_design/list',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function RGBtoCMYK(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_boxorder_design/RGBtoCMYK',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
export function printNameCardOrderJson(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_namecard_order/getFile',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
export function printNameCardOrderDownload(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_namecard_order/downloadPDF',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
export function printNameCardOrderRemovePDF(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_namecard_order/removePDF',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function PrintTemplateGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/print_template/get/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}

export function templateChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_template/changestatus',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
export function printTemplateEdit(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/print_template/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function printTtemplatelistWithStyles(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch('api/print_template/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PrintTemplateChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_template/changestatus',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function PrintTemplateDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_template/delete/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function PrintTemplateConnectOrg(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_template/connectOrg',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
export function PrintTemplateConnectOrgBack(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_template/connectOrgBack',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
export function PrintTemplateConnectback(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_template/connectback',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function PrintTemplateBackget(id,request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_template/backget/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function PrintBackgroundDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_background/delete/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function PrintBackgroundChangeStatus(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    'api/print_background/changestatus',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function PrintElementDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_element/delete/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function PrintElementChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_element/changestatus',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function printElementList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/print_element/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function printElementEdit(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/print_element/update', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function printElementCollectionAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_element_collection/add',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function printElementCollectionList(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    'api/print_element_collection/list',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function printElementCollectionUpdate(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    'api/print_element_collection/update',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function printElementCollectionchangestatus(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    'api/print_element_collection/changestatus',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function printElementCollectionDelete(
  id,
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    'api/print_element_collection/delete/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function printElementCollectionGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_element_collection/get/' +id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function printElementCollectionConnect(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_element_collection/connect',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function printElementCollectionConnectlist(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_element_collection/connectlist',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function printElementCollectionConnectdelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/print_element_collection/connectdelete/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function printBackgroundList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/print_background/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function printPhotosList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/print_Photos/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [Privilege]
// EmployeeList =>request(GetEmployeeByIdReq)
export function UserPrivList(id, request, successCallback, failCallback, method) {
  baseFetch('api/priv/userprivlist/' + id, request, successCallback, failCallback, method)
  return ''
}

//PrivGroupList List =>request(BaseRequest)
export function PrivilegeGroupList(request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/listmpriv_group', request, successCallback, failCallback, method)
  return ''
}

//PrivGroupAdd Add =>request(PrivilegeGroupAddReq)
export function PrivilegeGroupAdd(request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/addmpriv_group', request, successCallback, failCallback, method)
  return ''
}

//PrivGroupDelete Delete =>request(PrivilegeGroupDeleteReq)
export function PrivilegeGroupDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/deletempriv_group/' + id, request, successCallback, failCallback, method)
  return ''
}

//PrivList Delete =>request(PrivilegeGroupAddReq)
export function PrivilegeGroupGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/getmpriv_group/' + id, request, successCallback, failCallback, method)
  return ''
}

//PrivList Delete =>request(PrivilegeGroupAddReq)
export function PrivilegeGroupUpdate(request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/updatempriv_group', request, successCallback, failCallback, method)
  return ''
}

//PrivList List =>request(BaseRequest)
export function PrivilegeList(request, successCallback, failCallback, method) {
  baseFetch('api/priv/listpriv', request, successCallback, failCallback, method)
  return ''
}

//PrivList Add =>request(PrivilegeAddReq)
export function PrivilegeAdd(request, successCallback, failCallback, method) {
  baseFetch('api/priv/addmpriv', request, successCallback, failCallback, method)
  return ''
}

//PrivList Delete =>request(PrivilegeDeleteReq)
export function PrivilegeDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/priv/deletempriv/' + id, request, successCallback, failCallback, method)
  return ''
}

//PrivList Delete =>request(PrivilegeUserListReq)
export function GetUSerPrivile(id, request, successCallback, failCallback, method) {
  baseFetch('api/priv/getmpriv/' + id, request, successCallback, failCallback, method)
  return ''
}

//PrivList Delete =>request(PrivilegeUserListReq)
export function UpdateMPriv(request, successCallback, failCallback, method) {
  baseFetch('api/priv/updatempriv', request, successCallback, failCallback, method)
  return ''
}

//AddMPriv_GroupUser =>request(PrivilegeMP_GroupUserAddReq)
export function AddMPriv_GroupUser(request, successCallback, failCallback, method) {
  baseFetch('api/privgroupuser/addmpriv_groupuser', request, successCallback, failCallback, method)
  return ''
}

//UpdateMPriv_GroupUser =>request(PrivilegeMP_GroupUserAddReq)
export function UpdateMPriv_GroupUser(request, successCallback, failCallback, method) {
  baseFetch(
    'api/privgroupuser/updatempriv_groupuser',
    request,
    successCallback,
    failCallback,
    method
  )
  return ''
}

//AddMPriv_GroupPriv =>request(AddMPriv_GroupPrivReq)
export function AddMPriv_GroupPriv(request, successCallback, failCallback, method) {
  baseFetch('api/privgrouppriv/addmpriv_grouppriv', request, successCallback, failCallback, method)
  return ''
}

//GetMPriv_GroupPriv =>request(GetMPriv_GroupPrivReq)
export function GetMPriv_GroupPriv(request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/addmpriv_grouppriv', request, successCallback, failCallback, method)
  return ''
}

export function GetMPriv_GetAllMPGroupPriv(request, successCallback, failCallback, method) {
  baseFetch('api/privgroup/getallmpriv_grouppriv', request, successCallback, failCallback, method)
  return ''
}

//ListMPriv_GroupPriv =>request(ListMPriv_GroupPrivReq)
export function ListMPriv_GroupPriv(id, request, successCallback, failCallback, method) {
  baseFetch(
    'api/privgrouppriv/listmpriv_grouppriv/' + id,
    request,
    successCallback,
    failCallback,
    method
  )
  return ''
}

//UpdateMPriv_GroupPriv =>request(AddMPriv_GroupPrivReq)
export function UpdateMPriv_GroupPriv(request, successCallback, failCallback, method) {
  baseFetch(
    'api/privgrouppriv/updatempriv_grouppriv',
    request,
    successCallback,
    failCallback,
    method
  )
  return ''
}

//UpdateMPriv_GroupPriv =>request(GetEmployeeByIdReq)
export function ListMPriv_GroupsForUser(userkey, request, successCallback, failCallback, method) {
  baseFetch(
    'api/privgroupuser/getmpriv_groupuser/' + userkey,
    request,
    successCallback,
    failCallback,
    method
  )
  return ''
}
//#endregion

//#region [Log]
export function LogAction(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/log/action', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function LogError(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/log/error', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [News]
export function NewsList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsChangeImage(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news/changeimage', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/news/get/' + id, request, successCallback, failCallback, method)
  return ''
}

export function NewsDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/news/delete/' + id, request, successCallback, failCallback, method)
  return ''
}

export function NewsChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news/changeStatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsContentAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news_content/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsContentUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news_content/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function NewsContentGet(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/news_content/get', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [Pages]
export function PagesList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/pages/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PagesAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/pages/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PagesUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/pages/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PagesGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/pages/get/' + id, request, successCallback, failCallback, method)
  return ''
}

export function PagesDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/pages/delete/' + id, request, successCallback, failCallback, method)
  return ''
}

export function PagesContentAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/pages_content/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PagesContentUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/pages_content/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PagesContentGet(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/pages_content/get', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [Videos]
export function VideosList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/videos/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function VideosAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/videos/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function VideosUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/videos/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function VideosGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/videos/get/' + id, request, successCallback, failCallback, method)
  return ''
}

export function VideosDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/videos/delete/' + id, request, successCallback, failCallback, method)
  return ''
}

export function VideosChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/videos/changeStatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function VideoChangeImage(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/videos/changeimage', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [User]
export function UserList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/user/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function UserSungah(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/user/sungah', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function UserGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/user/get/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}

export function UserDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/user/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}

export function UserAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/user/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function UserUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/user/update', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [Dashboard]
export function DashboardPrintorder(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dashboard/printorder', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DashboardPrintstatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dashboard/printstatus', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function DashboardPrintTemplatestatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dashboard/printtemplatestatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

//#endregion

//#region [Banner]
export function BannerList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/banner/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function BannerAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/banner/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function BannerUpdateTolov(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/banner/updatetolov', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function BannerUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/banner/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function BannerDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/banner/delete/' + id, request, successCallback, failCallback, method)
  return ''
}
//#endregion

//#region [Page]
export function PageList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/huudas/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PageAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/huudas/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PageUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/huudas/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function PageGet(id, request, successCallback, failCallback, method) {
  baseFetch('api/huudas/get/' + id, request, successCallback, failCallback, method)
  return ''
}

export function PageDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/huudas/delete/' + id, request, successCallback, failCallback, method)
  return ''
}

//#endregion

//#region [DTemaplete]
export function DTemapleteTagsUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dtemplate/updatetags', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DTemapleteAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dtemplate/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DTemapleteUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dtemplate/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DTemapleteChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dtemplate/changestatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DTemapleteUpdateInfo(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dtemplate/updateinfo', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DTemapleteList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dtemplate/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DTemapleteListWithTracking(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    'api/dtemplate/listwithtracking',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function DTemapleteGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dtemplate/get/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DTemapleteDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dtemplate/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DDesigners(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dtemplate/designers', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DTemplateMake(request, successCallback, failCallback, method, checkPriv, rHeader) {
  baseFetch(
    'api/dtemplate_make/list',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function DTemplateMakelistByUser(
  request,
  successCallback,
  failCallback,
  method,
  checkPriv,
  rHeader
) {
  baseFetch(
    'api/dtemplate_make/listByUser',
    request,
    successCallback,
    failCallback,
    method,
    checkPriv,
    rHeader
  )
  return ''
}

export function DTemplateChangeOrg(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dtemplate/changeorg', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [DUpload]
export function DUploadAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dupload/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DUploadList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dupload/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DUploadDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dupload/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DUploadDesigners(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dupload/designers', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DImageUpdateInfo(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dupload/update', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [DFonts]
export function DFontsAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dfonts/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DFontsChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dfonts/changestatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DFontsList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dfonts/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DFontsDesigners(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dfonts/designers', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DFontsDelete(id, request, successCallback, failCallback, method) {
  baseFetch('api/dfonts/delete/' + id, request, successCallback, failCallback, method)
  return ''
}
//#endregion

//#region [DElement]
export function DElementChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/delement/changestatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DElementChangeBBStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/delement/changelevel', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DElementTagsUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/delement/updatetags', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DElementAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/delement/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DElementDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/delement/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DElementList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/delement/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DElementDesigners(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/delement/designers', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DElementUpdateInfo(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/delement/update', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [DTextStyles]
export function DTextStylesList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dtext_styles/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DTextStylesChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/dtext_styles/changestatus',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function DTextStylesDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/dtext_styles/delete/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
//#endregion

//#region [DCollection]
export function DCollectionChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dcollection/changestatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DCollectionChangeImage(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dcollection/changeimage', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DCollectionAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dcollection/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DCollectionUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dcollection/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DCollectionDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dcollection/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DCollectionList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dcollection/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [Designers]
export function DesignerAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/designer/add', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function DesignerChangeOrg(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/designer/changeorg', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function DesignerUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/designer/update', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function DesignerList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/designer/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function DesignerDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/designer/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function DesignerGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/designer/get/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function DesignerUpdateLevel(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/designer/changeLevel', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function DesignerChangePass(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/designer/changepassword', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function DesignerBan(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/designer/ban', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function DesignerDesignerlist(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/designer/designerlist', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [Org]

export function OrgList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org/add', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org/get/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org/update', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgChangelogo(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org/changelogo', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org/changestatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function OrglogosList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_logos/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrglogosAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_logos/add', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrglogosDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_logos/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrglogosUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_logos/update', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrglogosChangelogo(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_logos/changelogo', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrglogosChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_logos/changestatus', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function OrgColorsList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_colors/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgColorsAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_colors/add', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgColorsDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_colors/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgColorsUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_colors/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function OrgFontsList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_fonts/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgFontsAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_fonts/add', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgFontsDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_fonts/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgFontsUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_fonts/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function OrgElementsList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_elements/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function OrgElementslistWithElement(
  request,
  successCallback,
  failCallback,
  method,
  rHeader
) {
  baseFetch(
    'api/org_elements/listWithElement',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
export function OrgElementsAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_elements/add', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgElementsDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/org_elements/delete/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
export function OrgElementsUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_elements/update', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function OrgPptList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_slides/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgPptAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_slides/add', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgPptDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_slides/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgPptChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_slides/changestatus', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgPptUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_slides/update', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgPptAddImage(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_slides/changelogo', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function OrgPdfList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_brandbook/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgPdfAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_brandbook/add', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgPdfDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/org_brandbook/delete/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
export function OrgPdfChangeStatus(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/org_brandbook/changestatus',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
export function OrgPdfUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_brandbook/update', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function OrgPdfAddImage(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/org_brandbook/changelogo', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [DWallet_History]
export function DWallet_HistoryAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dwallet_history/add', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DWallet_HistoryList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dwallet_history/list', request, successCallback, failCallback, method, rHeader)
  return ''
}

export function DWallet_HistoryDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/dwallet_history/delete/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}

export function DWallet_HistoryGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/dwallet_history/get/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
//#endregion

//#region [Order]
export function DesignerOrderGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/dwallet_history/get/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
//#endregion

//#region [Search]
export function SearchList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/search/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function SearchListWithTotal(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/search/listWithTotal', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [Subscription]
export function SubscriptionAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/subscription/add', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function SubscriptionDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/subscription/delete/' + id,
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
export function SubscriptionList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/subscription/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [School]
export function SchoolList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/school/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [LetterBanner]
export function LetterBannerList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dyorder/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function LetterBannerUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dyorder/changeug', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function LetterBannerGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dyorder/more/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function LetterBannerDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/dyorder/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [Feedback]
export function FeedbackList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/feedback/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function FeedbackDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/feedback/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion

//#region [QR]
export function QRadd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/qr/preadd', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function QRList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/qr/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function addQRCode(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/qr/addQRCode', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function deleteQRCode(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/qr/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function QRGet(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/qr/get', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function QRMore(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/qr/more', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function QREditName(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/qr/changename', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function QREditUrl(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/qr/changeURL', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function QRStatList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/qr_stats/byday', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function QRStatCityList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/qr_stats/bycity', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function QRStatOSList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/qr_stats/byos', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function QRStatDeviceList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/qr_stats/bydevice', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function QrGetByID(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/qr/getItem/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}

export function deleteEvent(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function EventSentMail(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event_user/sendemail', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function EventEditName(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event/changename', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function EventRegistration(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event_user/changeStatus', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function EventAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event/add', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function EventList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function EventUserAdd(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event_user/add', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function EventUserList(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event_user/list', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function EventUserDelete(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event_user/delete/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function EventUserUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event_user/update', request, successCallback, failCallback, method, rHeader)
  return ''
}
export function EventGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event/get/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function EventUserGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event_user/get/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function eventGet(id, request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event/get/' + id, request, successCallback, failCallback, method, rHeader)
  return ''
}
export function EventEmailStatusUpdate(request, successCallback, failCallback, method, rHeader) {
  baseFetch(
    'api/event_user/sendEmailStatus',
    request,
    successCallback,
    failCallback,
    method,
    rHeader
  )
  return ''
}
export function EventIrts(request, successCallback, failCallback, method, rHeader) {
  baseFetch('api/event/irts', request, successCallback, failCallback, method, rHeader)
  return ''
}
//#endregion
