import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {PrivList} from '../pages/privilege/Priv/PrivList'
import {PrivGroupList} from '../pages/privilege/PrivGroup/PrivGroupList'
import {PrivGroupMore} from '../pages/privilege/PrivGroup/PrivGroupMore'
import {EmployeeMore} from '../pages/employee/more'
import {MenuTestPage} from '../pages/MenuTestPage'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {Employee} from '../pages/employee'
import OrderList from '../pages/orders/box'
import {OrderMore} from '../pages/orders/box/more'
import CardOrder from '../pages/orders/card'
import {NameCardElement} from '../pages/elements/element/index'
import { Templates } from '../pages/name-card/templates'
import { NameCardBackground } from '../pages/name-card/NameCardBackground'
import { TemplateMore } from '../pages/name-card/templates/more'
import { CardMore } from '../pages/orders/card/more'
import { ElementBundle } from '../pages/elements/elementbudle'
import { ElelemetBundleMore } from '../pages/elements/elementbudle/more'
import { BackTemplates } from '../pages/name-card/backtemplates'
import { UserOrderEdit } from '../pages/edit/userOrderEdit'


const PrivateRoutes = () => {
 
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='/privilege/privlist' element={<PrivList />} />
        <Route path='/privilege/privgrouplist' element={<PrivGroupList />} />
        <Route path='/privilege/privgroup/:id' element={<PrivGroupMore />} />
        <Route path='/employee' element={<Employee />} />
        <Route path='/employeemore/:id' element={<EmployeeMore />} />
        <Route path='/templates' element={<Templates />} />
        <Route path='/backtemplates' element={<BackTemplates />} />
        <Route path='/NameCardBackground' element={<NameCardBackground />} />
        <Route path='/namecardEl' element={<NameCardElement />} />
        <Route path='/elementbundle' element={<ElementBundle />} />
        <Route path='/elementbundle/:id' element={<ElelemetBundleMore />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='/orderbox' element={<OrderList />} />
        <Route path='/ordercard' element={<CardOrder />} />
        <Route path='/order/:id' element={<OrderMore />} />
        <Route path='/ordercard/:id' element={<CardMore/>} />
        <Route path='/template/:id' element={<TemplateMore/>} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}



export {PrivateRoutes}
