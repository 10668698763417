import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import CustomModal from '../../../modules/CustomModal/modal'
import {dateTimeFormat, isNullOrEmpty, moneyFormat, OrderStatus} from '../../../../services/Utils'
import {
  BaseRequest,
  magicnum,
  PrintboxOrderDesignListreq,
  RGBtoCMYKreq,
} from '../../../../services/Request'
import {
  CDN_URL,
  PrintboxOrderDesignList,
  PrintboxOrderGet,
  PrintNamecardOrderGet,
  RGBtoCMYK,
} from '../../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'
import {Document, Page, pdfjs} from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import {Edit} from './edit'
import {NemeltAjilbar} from './NemeltAjilbar'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'OrderCard',
    path: '/ordercard',
    isSeparator: false,
    isActive: false,
  },
]

type Props = {
  type?: any
}

const CardMore: FC<Props> = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>([])
  const [mergedpdf, setMergedpdf] = useState<any>('')
  const [tab, setTab] = useState('image')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [validate, setValidate] = useState<any>([])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [pNumber, setPNumber] = useState('')
  const [provider, setProvider] = useState('')
  const [isDesigner, setIsDesigner] = useState('')
  const [count, setCount] = useState(0)
  const [tempTsaas, settempTsaas] = useState<any>(0)

  useEffect(() => {
    get()
  }, [id])

  useEffect(() => {
    if (data.burelt === 1) {
      const match = magicnum.bureltgu?.find((el: {id: string}) => el.id === data.tsaas.toString())
      settempTsaas(match)
    } else if (data.burelt === 2) {
      const match = magicnum.burelttei?.find((el: {id: string}) => el.id === data.tsaas.toString())
      settempTsaas(match)
    }
  }, [data])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = BaseRequest
    PrintNamecardOrderGet(id, req, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setMergedpdf(response.mergedPdf)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => setShowModal(false)}
          className='VideoModal'
        >
          <div className='mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
            <span style={{visibility: 'hidden'}}></span>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{t('common:USERS.activate')}</h1>
            </div>

            <div className='separator mt-8 d-flex flex-center mb-8'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
                {t('common:USERS.activate')}
              </span>
            </div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid'
                placeholder='name'
                onChange={(e) => {
                  setName(e.target.value)
                  validateChange('name', e)
                }}
                value={name}
              ></input>
              {validate['name'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid'
                value={email}
                placeholder='email'
                onChange={(e) => {
                  setEmail(e.target.value)
                  validateChange('email', e)
                }}
              ></input>
              {validate['email'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <input
                type='number'
                className='form-control form-control-solid'
                placeholder='mobile'
                onChange={(e) => {
                  setPNumber(e.target.value)
                  validateChange('pNumber', e)
                }}
                value={pNumber}
              ></input>
              {validate['pNumber'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>

            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setProvider(e.target.value)
                  validateChange('provider', e)
                }}
                className='form-select form-select-sm form-select-solid'
                name='provider'
                id=''
                value={provider}
              >
                <option value=''>{t('common:validSelect')}</option>
                <option value='google'>{t('common:USERS.google')}</option>
                <option value='facebook'>{t('common:USERS.facebook')}</option>
                <option value='grado'>{t('common:USERS.grado')}</option>
                <option value='web'>{t('common:USERS.web')}</option>
                <option value='admin'>{t('common:USERS.admin')}</option>
              </select>
              {validate['provider'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>
            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setIsDesigner(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                name='status'
                value={isDesigner}
                id=''
              >
                <option value={''}>{t('common:validSelect')}</option>

                <option value={'1'}>{t('common:USERS.designer')}</option>
                <option value={'0'}>{t('common:USERS.notDesigner')}</option>
              </select>
              {validate['isDesigner'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>

            <div className='pt-10' style={{textAlign: 'right'}}>
              <button
                className='btn btn-primary align-self-center'
                // onClick={isNullOrEmpty(data) ? save : update}
                disabled={loading}
              >
                {loading ? t('common:loading') : t('common:save')}
              </button>
            </div>
          </div>
        </CustomModal>
      ) : null}

      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{data.id}</PageTitle>
      <div className={`card mb-5`}>
        <div className='card-header card-header-overflow border-1'>
          <div className='flex gap-8 w-full card-header-flex'>
            <div className='flex gap-6 p-8' style={{fontSize: '14px', fontWeight: '500'}}>
              <div
                style={{
                  color: 'gray',
                  border: '2px solid #eee',
                  borderRadius: '8px',
                  padding: '12px',
                  position: 'relative',
                  width: '250px',
                }}
                className='flex flex-col'
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '-20px',
                    backgroundColor: 'white',
                    padding: '4px 8px',
                  }}
                  className='flex gap-2 items-center'
                >
                  <i
                    style={{fontSize: '20px', paddingTop: '4px'}}
                    className='fa-regular fa-user'
                  ></i>
                  <span>Хэрэглэгч</span>
                </div>
                <span>
                  Нэр: <span style={{color: 'black'}}>{data.userner}</span>
                </span>
                <span>
                  Утас:{' '}
                  <span style={{color: 'black'}}>
                    {data?.umobile1}, {data?.umobile2}
                  </span>
                </span>
                <span>
                  Email: <span style={{color: 'black'}}>{data?.uemail}</span>
                </span>

                <span>
                  Огноо: <span style={{color: 'black'}}>{dateTimeFormat(data.created_at)}</span>
                </span>
              </div>
              <div
                style={{
                  color: 'gray',
                  border: '2px solid #eee',
                  borderRadius: '8px',
                  padding: '12px',
                  position: 'relative',
                  width: '250px',
                }}
                className='flex flex-col'
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '-20px',
                    backgroundColor: 'white',
                    padding: '4px 8px',
                  }}
                  className='flex gap-2 items-center'
                >
                  <i
                    style={{fontSize: '20px', paddingTop: '4px'}}
                    className='fa-solid fa-layer-group'
                  ></i>
                  <span>Захиалга</span>
                </div>
                <span>
                  Төрөл:{' '}
                  <span style={{color: 'black'}}>
                    <span>{t(`common:magicnum.namecardtype.${data.type}`)}</span>
                  </span>
                </span>
                <span>
                  Цаас:{' '}
                  <span style={{color: 'black'}}>
                    {t(`common:magicnum.namecardPaper.${data.tsaas}`)}
                  </span>
                </span>
                <span>
                  Бүрэлт:{' '}
                  <span style={{color: 'black'}}>
                    {t(`common:magicnum.namecardBurelt.${data.burelt}`)}
                  </span>
                </span>
                <span>
                  Булан:{' '}
                  <span style={{color: 'black'}}>{data?.corner === 1 ? 'Тэгш' : 'Дугуй'}</span>
                </span>
                <span>
                  Тоо: <span style={{color: 'black'}}>{data?.count}ш</span>
                </span>
              </div>
              <div
                style={{
                  color: 'gray',
                  border: '2px solid #eee',
                  borderRadius: '8px',
                  padding: '12px',
                  position: 'relative',
                  width: '250px',
                }}
                className='flex flex-col'
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '-20px',
                    backgroundColor: 'white',
                    padding: '4px 8px',
                  }}
                  className='flex gap-2 items-center'
                >
                  <i
                    style={{fontSize: '20px', paddingTop: '4px'}}
                    className='fa-solid fa-dollar-sign'
                  ></i>
                  <span>Төлбөр, төлөв:</span>
                </div>
                <span className='flex gap-2'>
                  <span>Төлөв:</span>
                  <div
                    style={{
                      backgroundColor: OrderStatus(data.status),
                      color: 'black',
                      padding: '2px 8px',
                      borderRadius: '8px',
                    }}
                  >
                    {t(`common:magicnum.PrintStatus.${data?.status}`)}
                  </div>
                </span>
                <span className='flex gap-2'>
                  <span>Төлбөр:</span>
                  <div className='flex gap-3'>
                    <span style={{color: 'black'}}>{moneyFormat(data?.total, 'MNT')}</span>
                  </div>
                </span>
                <span className='flex gap-2'>
                  <span>Төрөл:</span>
                  <span style={{color: 'black', textTransform: 'uppercase'}}>{data?.payment}</span>
                </span>
              </div>
              <div
                style={{
                  color: 'gray',
                  border: '2px solid #eee',
                  borderRadius: '8px',
                  padding: '12px',
                  position: 'relative',
                  width: '250px',
                }}
                className='flex flex-col'
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '-20px',
                    backgroundColor: 'white',
                    padding: '4px 8px',
                  }}
                  className='flex gap-2 items-center'
                >
                  <i
                    style={{fontSize: '20px', paddingTop: '4px'}}
                    className='fa-solid fa-map-location-dot'
                  ></i>
                  <span>Хаяг:</span>
                </div>

                <span>
                  Хаяг: <span style={{color: 'black'}}>{data?.uaddress}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'image',
                })}
                onClick={() => setTab('image')}
                role='tab'
              >
                Загварууд
              </div>
            </li>
            {(!isNullOrEmpty(data.front_ajilbar) || !isNullOrEmpty(data.back_ajilbar)) && (
              <li className='nav-item'>
                <div
                  className={clsx(`nav-link cursor-pointer`, {
                    active: tab === 'ajilbar',
                  })}
                  onClick={() => setTab('ajilbar')}
                  role='tab'
                >
                  Нэмэлт ажилбар
                </div>
              </li>
            )}
          </ul>
        </div>
        <form className='form'>
          {tab === 'image' && !isNullOrEmpty(data) && <Edit data={data} />}
          {tab === 'ajilbar' && (
              <NemeltAjilbar data={data} />
            )}
        </form>
      </div>
    </>
  )
}

export {CardMore}
