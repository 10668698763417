import {FC, useEffect, useRef, useState} from 'react'
import {fabric} from 'fabric'
import {
  printNameCardOrderDownloadReq,
  printNameCardOrderJsonReq,
  printNameCardOrderRemovePDFRef,
} from '../../../../services/Request'
import {
  CDN_URL,
  printNameCardOrderDownload,
  printNameCardOrderJson,
  printNameCardOrderRemovePDF,
} from '../../../../services/main'
import clsx from 'clsx'
import {CanvasDimensions, isNullOrEmpty} from '../../../../services/Utils'
import {useTranslation} from 'react-i18next'

type Props = {
  data?: any
}

const exportToBase64 = async (canvas: any) => {
  if (canvas) {
    try {
      const dataURL = canvas.toDataURL({
        format: 'png',
        quality: 1,
        pixelRatio: 3,
        multiplier: 3,
      })
      return dataURL
    } catch (error) {
      console.error('Error exporting to Base64', error)
    }
  }
}
const lockAllObjects = async (canvas: any) => {
  if (!canvas) return

  canvas.getObjects().forEach((obj: any) => {
    if (!obj.userLock) {
      obj.set({
        selectable: false,
        evented: false,
        hasControls: false,
      })
    }
  })

  canvas.renderAll()
}

const unLockAllObjects = async (canvas: any) => {
  if (!canvas) return
  canvas.getObjects().forEach((obj: any) => {
    if (!obj.userLock) {
      obj.set({
        selectable: true,
        evented: true,
        hasControls: true,
      })
    }
  })

  canvas.renderAll()
}
type Font = {
  family: string
  url: string
}
const loadFonts = (fonts: Font[]): Promise<FontFace[]> => {
  return Promise.all(
    fonts.map(({family, url}: Font) =>
      new FontFace(family, `url(${url})`)
        .load()
        .then((loadedFont) => {
          document.fonts.add(loadedFont)
          return loadedFont
        })
        .catch((err) => {
          console.error(`Фонт ачаалж чадсангүй: ${family}`, err)
          return null
        })
    )
  ).then((loadedFonts) => loadedFonts.filter(Boolean) as FontFace[])
}

const homeBackTemplateLoad = async (jsonData: any, canvas: any, ajilbarObjects: any) => {
  if (!canvas || !jsonData.objects) return

  try {
    const filteredObjects = jsonData.objects.filter((obj: any) => !isNullOrEmpty(obj?.ajilbar))

    if (filteredObjects.length === 0) return

    const filteredJson = {...jsonData, objects: filteredObjects}

    await Promise.all([
      canvas.loadFromJSON(filteredJson, () => {
        canvas.requestRenderAll()
      }),
    ])

    let ajilbarObjectsTemp: any[] = []
    filteredObjects.forEach((obj: any) => {
      let object = {name: '', value: ''}
      if (obj.type === 'Image' && obj.src) {
        const imageUrl = obj.src
        object = {
          name: obj.name,
          value: imageUrl,
        }
        ajilbarObjectsTemp.push(object)
      } else if (obj.type === 'Itext' || obj.type === 'Textbox') {
        const textContent = obj.text
        object = {
          name: obj.name,
          value: textContent,
        }
        ajilbarObjectsTemp.push(object)
      } else if (obj.type === 'Group') {
        const element = obj.ElementUrl
        object = {
          name: obj.name,
          value: element,
        }
        ajilbarObjectsTemp.push(object)
      }
    })

    ajilbarObjects(ajilbarObjectsTemp)
    canvas.backgroundColor = '#0000'
    const fontsToLoad = canvas
      .getObjects()
      .filter((obj: any) => (obj.type === 'IText' || obj.type === 'Textbox') && obj.fontURL)
      .map((obj: any) => ({
        family: obj.fontFamily,
        url: obj.fontURL,
      }))

    // await loadFonts(fontsToLoad)
    canvas.renderAll()
  } catch (error) {
    console.error('Error loading template:', error)
  }
}

const initializeCanvas = async (
  id: any,
  width: any,
  height: any,
  data: any,
  edit: any,
  canvasRef: any,
  ajilbarObjects: any,
  setLoading: any
) => {
  try {
    const canvas = new fabric.Canvas(id, {
      width: width,
      height: height,
    })

    await homeBackTemplateLoad(data, canvas, ajilbarObjects)
    if (edit === 'lock') {
      lockAllObjects(canvas)
    } else {
      unLockAllObjects(canvas)
    }
    canvasRef.current = canvas
    canvas.renderAll()
    return canvas
  } catch {
  } finally {
    setLoading(false)
  }
}

const NemeltAjilbar: FC<Props> = ({data}) => {
  const [edit, setEdit] = useState<any>('lock')
  const [loading, setLoading] = useState<any>(false)
  const [canvasData, setCanvasData] = useState<any>([])
  const canvasRefBack = useRef<any>(null)
  const canvasRefFront = useRef<any>(null)
  const [ajilbarObjectsBack, setAjilbarObjectsBack] = useState<any>([])
  const [ajilbarObjectsFront, setAjilbarObjectsFront] = useState<any>([])

  const {t} = useTranslation()
  useEffect(() => {
    userOrderJson()
  }, [data])

  const userOrderJson = () => {
    setLoading(true)
    const req = {...printNameCardOrderJsonReq}
    req.file = {
      back: data.back_json,
      front: data.front_json,
    }
    printNameCardOrderJson(req, onGetSuccess, onFailed, 'POST')
  }

  const downloadPdf = (sides: any) => {
    setLoading(true)
    const req = {...printNameCardOrderDownloadReq}
    req.order = {
      sides: sides,
      uid: data.uid,
    }
    printNameCardOrderDownload(req, onDownSuccess, onFailed, 'POST')
  }
  const removePdf = (url: any) => {
    const req = {...printNameCardOrderRemovePDFRef}
    req.order = {
      downloadUrl: url,
    }
    printNameCardOrderRemovePDF(req, onRemoveSuccess, onFailed, 'POST')
  }

  const onGetSuccess = (response: any) => {
    setCanvasData(response)
    const {width, height} = CanvasDimensions(data.size, 'px')
    initializeCanvas(
      '3',
      width,
      height,
      response.front,
      edit,
      canvasRefFront,
      setAjilbarObjectsFront,
      setLoading
    )
    initializeCanvas(
      '4',
      width,
      height,
      response.back,
      edit,
      canvasRefBack,
      setAjilbarObjectsBack,
      setLoading
    )
  }

  const onRemoveSuccess = (response: any) => {}

  const onDownSuccess = async (response: any) => {
    await DownloadPDF(response.downloadUrl, 'design-image')
  }

  const onFailed = (error: any) => {
    setLoading(false)
  }

  const toggleLock = () => {
    setEdit(() => {
      return edit === 'lock' ? 'normal' : 'lock'
    })
  }
  const [audioPlaying, setAudioPlaying] = useState(false)

  useEffect(() => {
    if (canvasData) {
      if (!audioPlaying) {
        setAudioPlaying(true)
        return
      } else {
        const {width, height} = CanvasDimensions(data.size, 'px')
        initializeCanvas(
          '4',
          width,
          height,
          canvasData.back,
          edit,
          canvasRefBack,
          setAjilbarObjectsBack,
          setLoading
        )
        initializeCanvas(
          '3',
          width,
          height,
          canvasData.front,
          edit,
          canvasRefFront,
          setAjilbarObjectsFront,
          setLoading
        )
      }
    }
  }, [edit, canvasData])

  const download = async (type: any, event: React.MouseEvent) => {
    let base64 = ''
    if (type === 'front') {
      base64 = await exportToBase64(canvasRefFront.current)
    } else {
      base64 = await exportToBase64(canvasRefBack.current)
    }

    DownloadBase64(base64, 'type', event)
  }

  const DownloadPDF = async (url: any, name: any) => {
    try {
      const response = await fetch(CDN_URL + url)

      if (!response.ok) throw new Error('Network response was not ok')
      const blob = await response.blob()
      const urlObject = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = urlObject
      link.download = name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(urlObject)
    } catch (error) {
      console.error('Image download failed:', error)
    } finally {
      setLoading(false)
      removePdf(url)
    }
  }

  const DownloadBase64 = async (url: any, name: any, event: React.MouseEvent) => {
    event.preventDefault()
    try {
      const link = document.createElement('a')
      link.href = url
      link.download = `${name}.png`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error('Image download failed:', error)
    } finally {
      setLoading(false)
    }
  }

  const downloadAll = async (event: React.MouseEvent) => {
    const frontBase64 = await exportToBase64(canvasRefFront.current)
    const backBase64 = await exportToBase64(canvasRefBack.current)

    DownloadBase64(frontBase64, 'front', event)
    DownloadBase64(backBase64, 'back', event)
  }

  return (
    <div>
      <div className='card-body py-6'>
        <div
          className='ajilbar-container'
          style={{
            gap: '10px',
          }}
        >
          {ajilbarObjectsFront.length > 0 && (
            <div
              className='ajilbar-side'
              style={{
                border: '1px solid #e3e3e3',
                padding: '7px',
              }}
            >
              <h3>Урд тал</h3>
              {ajilbarObjectsFront.map((obj: any, index: number) => (
                <div key={index} className='ajilbar-item'>
                  {obj.value && obj.value.includes('http') ? (
                    <div className='ajilbar-image-container'>
                      <p className='ajilbar-name'>{t(`common:magicnum.objectName.${obj.name}`)}:</p>
                      <img src={obj.value} alt={obj.name} className='ajilbar-image' />
                    </div>
                  ) : (
                    <div className='ajilbar-text-container'>
                      <p className='ajilbar-name'>{t(`common:magicnum.objectName.${obj.name}`)}:</p>
                      <p className='ajilbar-text'>{obj.value}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {ajilbarObjectsBack.length > 0 && (
            <div
              className='ajilbar-side'
              style={{
                border: '1px solid #e3e3e3',
                padding: '7px',
              }}
            >
              <h3>Ар тал</h3>
              {ajilbarObjectsBack.map((obj: any, index: number) => (
                <div key={index} className='ajilbar-item'>
                  {obj.value && obj.value.includes('http') ? (
                    <div
                      className='ajilbar-image-container'
                      style={{
                        alignItems: 'center',
                      }}
                    >
                      <p className='ajilbar-name'>{t(`common:magicnum.objectName.${obj.name}`)}:</p>
                      <img src={obj.value} alt={obj.name} className='ajilbar-image' />
                    </div>
                  ) : (
                    <div className='ajilbar-text-container'>
                      <p className='ajilbar-name'>{t(`common:magicnum.objectName.${obj.name}`)}:</p>
                      <p className='ajilbar-text'>{obj.value}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className='flex flex-col gap-5'>
          {(canvasRefFront.current && canvasRefFront.current.getObjects().length > 0) && (
            <div className='flex flex-col items-center' style={{width: 'fit-content'}}>
              <div
                className='flex justify-between px-5 items-center bg-secondary'
                style={{width: '100%', height: '50px', borderRadius: '8px 8px 0 0 '}}
              >
                <span style={{textTransform: 'capitalize', fontSize: '16px', fontWeight: '600'}}>
                  Урд тал
                </span>
                <div style={{display: 'flex', gap: '10px'}}>
                  <button
                    disabled={loading}
                    onClick={(event) => downloadAll(event)}
                    className='btn btn-primary'
                  >
                    2 талыг татах
                  </button>
                  <button
                    disabled={loading}
                    onClick={(event) => {
                      download('front', event)
                    }}
                    className='bg-primary'
                    style={{
                      color: 'white',
                      padding: '6px 16px',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      fontSize: '14px',
                      border: 'none',
                    }}
                  >
                    Татах
                  </button>
                  <div
                    className='bg-warning'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'white',
                      padding: '0 16px',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      fontSize: '14px',
                    }}
                    onClick={toggleLock}
                  >
                    {edit === 'lock' ? 'Засах' : 'Цуцлах'}
                  </div>
                </div>
              </div>

              <div>
                <div
                  key={'front' + edit}
                  style={{
                    overflow: 'hidden',
                    height: 'auto',
                    transform: `scale(0.7)`,
                    transformOrigin: 'center',
                  }}
                >
                  {loading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      Уншиж байна...
                    </div>
                  ) : data?.back ? (
                    <canvas
                      ref={canvasRefFront}
                      id='3'
                      style={{
                        zIndex: 999999,
                        boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.9)',
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {(canvasRefBack.current && canvasRefBack.current.getObjects().length > 0) && (
            <div className='flex flex-col items-center' style={{width: 'fit-content'}}>
              <div
                className='flex justify-between px-5 items-center bg-secondary'
                style={{width: '100%', height: '50px', borderRadius: '8px 8px 0 0 '}}
              >
                <span style={{textTransform: 'capitalize', fontSize: '16px', fontWeight: '600'}}>
                  Ар тал
                </span>
                <div style={{display: 'flex', gap: '10px'}}>
                  <button
                    disabled={loading}
                    onClick={(event) => downloadAll(event)}
                    className='btn btn-primary'
                  >
                    2 талыг татах
                  </button>
                  <button
                    disabled={loading}
                    onClick={(event) => {
                      download('front', event)
                    }}
                    className='bg-primary'
                    style={{
                      color: 'white',
                      padding: '6px 16px',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      fontSize: '14px',
                      border: 'none',
                    }}
                  >
                    Татах
                  </button>
                  <div
                    className='bg-warning'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'white',
                      padding: '0 16px',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      fontSize: '14px',
                    }}
                    onClick={toggleLock}
                  >
                    {' '}
                    {edit === 'lock' ? 'Засах' : 'Цуцлах'}
                  </div>
                </div>
              </div>

              <div style={{}}>
                <div
                  key={'back' + edit}
                  style={{
                    overflow: 'hidden',
                    border: '1px solid #eee',
                    height: 'auto',
                    transform: `scale(0.7)`,
                    transformOrigin: 'center',
                  }}
                >
                  {loading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      Уншиж байна...
                    </div>
                  ) : data?.back ? (
                    <canvas
                      ref={canvasRefBack}
                      id='4'
                      style={{
                        zIndex: 999999,
                        boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.9)',
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {NemeltAjilbar}
